<template>
  <gf-container v-loading="loading">
    <el-dialog v-loading="newBrandModalLoading" :close-on-click-modal="false" :visible.sync="newBrandModalVisible" append-to-body title="Add New Brand">
      <gf-form>
        <template #alert>
          <gf-alert>
            <template #icon>
              <span class="svg-icon svg-icon-primary svg-icon-md">
                <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                    <rect height="24" width="24" x="0" y="0"/>
                    <path d="M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M11.613922,13.2130341 C11.1688026,13.6581534 10.4887934,13.7685037 9.92575695,13.4869855 C9.36272054,13.2054673 8.68271128,13.3158176 8.23759191,13.760937 L6.72658218,15.2719467 C6.67169475,15.3268342 6.63034033,15.393747 6.60579393,15.4673862 C6.51847004,15.7293579 6.66005003,16.0125179 6.92202169,16.0998418 L8.27584113,16.5511149 C9.57592638,16.9844767 11.009274,16.6461092 11.9783003,15.6770829 L15.9775173,11.6778659 C16.867756,10.7876271 17.0884566,9.42760861 16.5254202,8.3015358 L15.8928491,7.03639343 C15.8688153,6.98832598 15.8371895,6.9444475 15.7991889,6.90644684 C15.6039267,6.71118469 15.2873442,6.71118469 15.0920821,6.90644684 L13.4995401,8.49898884 C13.0544207,8.94410821 12.9440704,9.62411747 13.2255886,10.1871539 C13.5071068,10.7501903 13.3967565,11.4301996 12.9516371,11.8753189 L11.613922,13.2130341 Z" fill="#000000"/>
                  </g>
                </svg>
              </span>
            </template>
            Input the details of the new brand below
          </gf-alert>
        </template>
        <el-form ref="brand" :model="brand" :rules="brandRule" class="auto-form-item-width" label-width="70px" size="small">
          <el-form-item label="Brand" prop="name">
            <el-input v-model="brand.name" placeholder="Brand Name"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <gf-button type="secondary" @click="newBrandModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                    <g fill="#000000" transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)">
                      <rect height="2" rx="1" width="16" x="0" y="7"/>
                      <rect height="2" opacity="0.3" rx="1" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " width="16" x="0" y="7"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="primary" @click="newBrand">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                    <rect fill="#000000" height="2" rx="1" width="16" x="4" y="11"/>
                    <rect fill="#000000" height="2" opacity="0.3" rx="1" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " width="16" x="4" y="11"/>
                  </g>
                </svg>
              </span>
            </template>
            Add
          </gf-button>
        </template>
      </gf-form>
    </el-dialog>
    <el-dialog v-loading="newProductTypeModalLoading" :close-on-click-modal="false" :visible.sync="newProductTypeModalVisible" append-to-body title="Add New Product Type">
      <gf-form>
        <template #alert>
          <gf-alert>
            <template #icon>
              <span class="svg-icon svg-icon-primary svg-icon-md">
                <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                    <rect height="24" width="24" x="0" y="0"/>
                    <path d="M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M11.613922,13.2130341 C11.1688026,13.6581534 10.4887934,13.7685037 9.92575695,13.4869855 C9.36272054,13.2054673 8.68271128,13.3158176 8.23759191,13.760937 L6.72658218,15.2719467 C6.67169475,15.3268342 6.63034033,15.393747 6.60579393,15.4673862 C6.51847004,15.7293579 6.66005003,16.0125179 6.92202169,16.0998418 L8.27584113,16.5511149 C9.57592638,16.9844767 11.009274,16.6461092 11.9783003,15.6770829 L15.9775173,11.6778659 C16.867756,10.7876271 17.0884566,9.42760861 16.5254202,8.3015358 L15.8928491,7.03639343 C15.8688153,6.98832598 15.8371895,6.9444475 15.7991889,6.90644684 C15.6039267,6.71118469 15.2873442,6.71118469 15.0920821,6.90644684 L13.4995401,8.49898884 C13.0544207,8.94410821 12.9440704,9.62411747 13.2255886,10.1871539 C13.5071068,10.7501903 13.3967565,11.4301996 12.9516371,11.8753189 L11.613922,13.2130341 Z" fill="#000000"/>
                  </g>
                </svg>
              </span>
            </template>
            Input the details of the new product type below
          </gf-alert>
        </template>
        <el-form ref="productType" :model="productType" :rules="productTypeRule" class="auto-form-item-width" label-width="120px" size="small">
          <el-form-item label="Product Type" prop="name">
            <el-input v-model="productType.name" placeholder="Product Type Name"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <gf-button type="secondary" @click="newProductTypeModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                    <g fill="#000000" transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)">
                      <rect height="2" rx="1" width="16" x="0" y="7"/>
                      <rect height="2" opacity="0.3" rx="1" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " width="16" x="0" y="7"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="primary" @click="newProductType">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                    <rect fill="#000000" height="2" rx="1" width="16" x="4" y="11"/>
                    <rect fill="#000000" height="2" opacity="0.3" rx="1" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " width="16" x="4" y="11"/>
                  </g>
                </svg>
              </span>
            </template>
            Add
          </gf-button>
        </template>
      </gf-form>
    </el-dialog>
    <gf-form :width="720">
      <el-form ref="product" :model="product" :rules="productRule" label-width="170px" size="medium">
        <el-form-item>
          <gf-alert>
            <template #icon>
              <span class="svg-icon svg-icon-primary svg-icon-md">
                <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                    <rect height="24" width="24" x="0" y="0"/>
                    <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "/>
                    <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                  </g>
                </svg>
              </span>
            </template>
            Provide details of this product below.
          </gf-alert>
        </el-form-item>
        <el-form-item class="mb-1">
          <el-checkbox v-model="product.is_active" :false-label="0" :true-label="1" @change="helpIndex = 1">This Product is Active</el-checkbox>
        </el-form-item>
        <el-form-item class="mb-4">
          <el-checkbox v-model="product.is_manufactured" :false-label="0" :true-label="1" disabled>This is a manufactured product</el-checkbox>
        </el-form-item>
        <el-form-item label="Product Image" prop="image_url">
          <ImageInput :image.sync="product.image_url" image-empty="/default/material-icon.jpg" @image-object="handleImageObject"/>
        </el-form-item>
        <el-form-item label="Product Name" prop="name">
          <el-input v-model="product.name" @focus="helpIndex = 2"></el-input>
        </el-form-item>
        <el-form-item label="SKU">
          <el-input v-model="product.sku" disabled></el-input>
        </el-form-item>
        <el-form-item label="Units" prop="units">
          <el-input v-model="product.units"></el-input>
        </el-form-item>
        <div class="multiple-column">
          <el-form-item class="inline-input" label="Brand">
            <el-select v-model="product.brand_id" :remote-method="searchBrand" filterable remote style="width: 100%" @focus="helpIndex = 3">
              <el-option v-for="brand in brands" :key="brand.id" :label="brand.name" :value="brand.id"></el-option>
            </el-select>
          </el-form-item>
          <div class="right">
            <el-button icon="el-icon-plus" plain size="small" @click="newBrandModalVisible = true">New</el-button>
          </div>
        </div>
        <div class="multiple-column">
          <el-form-item class="inline-input" label="Product Type">
            <el-select v-model="product.product_type_id" :remote-method="searchProductType" filterable remote style="width: 100%" @focus="helpIndex = 4">
              <el-option v-for="productType in productTypes" :key="productType.id" :label="productType.name" :value="productType.id"></el-option>
            </el-select>
          </el-form-item>
          <div class="right">
            <el-button icon="el-icon-plus" plain size="small" @click="newProductTypeModalVisible = true">New</el-button>
          </div>
        </div>
        <el-form-item label="Product Description" style="margin-bottom: 35px">
          <el-input v-model="product.description" type="textarea" @focus="helpIndex = 5"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <gf-button v-if="product.is_draft" type="success" @click="publish">Publish</gf-button>
        <gf-button type="primary" @click="save">Save Changes</gf-button>
      </template>
    </gf-form>
    <template #aside>
      <gf-helper :content="helpInfo[helpIndex].content" :title="helpInfo[helpIndex].title"></gf-helper>
    </template>
  </gf-container>
</template>

<style lang="scss" scoped>
.el-form-item {
  width: 630px;
}

.auto-form-item-width {
  .el-form-item {
    width: auto;
  }
}

.multiple-column {
  display: flex;
  align-items: center;

  .right {
    margin-bottom: 30px;
    margin-left: 15px;
  }

  .left {
    width: 30%;
  }

  .flex-grow {
    flex-grow: 1;
  }
}
</style>

<script>
import BrandService from '@/services/v1/Brand'
import ProductTypeService from '@/services/v1/ProductType'
import ProductService from '@/services/v1/Product'
import FileUploadService from '@/services/v2/FileUpload'

// Components
import ImageInput from '@/component-v2/ImageInput'

export default {
  components: { ImageInput },
  data () {
    return {
      // ui
      loading: false,

      brands: [],
      productTypes: [],

      // Help
      helpInfo: [
        { title: 'Edit Product', content: 'Update the details of your product.' },
        { title: 'Product Status', content: 'A product is considered Active if it is currently being used / sold / purchased. Once a product is set as Disabled, it will no longer appear in your reports, as well as in Sales Orders and Purchase Orders. ' },
        { title: 'Product Name', content: 'Enter a generic name for your product. If the product contains different variants, you will be able to create more specific names when you create your variants later on.' },
        { title: 'Brand', content: 'Select or Add a new Brand for this product.' },
        { title: 'Product Type', content: 'Specify what category this product falls under in your product catalogue.' },
        { title: 'Description', content: 'A short sentence or phrase to describe what your product is.' }
      ],
      helpIndex: 0,

      // Main
      image_object: null,
      product: {
        name: null,
        brand_id: null,
        product_type_id: null,
        description: null,
        is_active: 0,
        image_url: null,
        is_manufactured: 0,
        is_draft: 0,
        brand: {
          name: null
        },
        product_type: {
          name: null
        }
      },

      // Modal
      brand: {
        name: null
      },
      productType: {
        name: null
      },

      // Rules
      productRule: {
        name: [
          { required: true, message: 'Name is require', trigger: 'blur' }
        ],
        units: [
          { required: true, message: 'Unit is require', trigger: 'blur' }
        ]
      },
      brandRule: {
        name: [
          { required: true, message: 'Name is require', trigger: 'blur' }
        ]
      },
      productTypeRule: {
        name: [
          { required: true, message: 'Name is require', trigger: 'blur' }
        ]
      },

      // Model
      newBrandModalVisible: false,
      newBrandModalLoading: false,
      newProductTypeModalVisible: false,
      newProductTypeModalLoading: false,

      // Filters
      brandFilter: {},
      productTypeFilter: {}
    }
  },
  methods: {
    handleImageObject (file) {
      this.image_object = file
    },
    save () {
      this.$refs.product.validate()
        .then(async () => {
          try {
            this.loading = true
            const product = this.$_.cloneDeep(this.product)
            delete product.brand
            delete product.product_type

            // If there is an image uploaded
            if (this.image_object) {
              const fd = new FormData()
              fd.append('image', this.image_object)
              fd.append('folder', 'product')

              const fileUploadService = new FileUploadService()
              const result = await fileUploadService.upload(fd)
              product.image_url = result.data.path
            }

            const pdService = new ProductService(this.$route.params.id)
            await pdService.update(product)
            this.$message.success(`${this.product.name} successfully updated`)
            await this.getProduct()
          } catch (error) {
            this.$Error(error)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },
    publish () {},

    // Brand Modal
    newBrand () {
      this.$refs.brand.validate()
        .then(async () => {
          try {
            this.newBrandModalLoading = true

            const bdService = new BrandService()
            const response = await bdService.create(this.brand)
            this.$message.success(`${this.brand.name} successfully created`)

            this.newBrandModalVisible = false
            this.searchBrand(this.brand.name)

            this.product.brand_id = response.data.id
            this.$ClearField('brand')
          } catch (error) {
            this.systemError()
          } finally {
            this.newBrandModalLoading = false
          }
        })
        .catch(() => {})
    },

    // Product Type Modal
    newProductType () {
      this.$refs.productType.validate()
        .then(async () => {
          try {
            this.newProductTypeModalLoading = true

            const ptService = new ProductTypeService()
            const response = await ptService.create(this.productType)
            this.$message.success(`${this.productType.name} successfully created`)

            this.newProductTypeModalVisible = false
            this.searchProductType(this.productType.name)

            this.product.product_type_id = response.data.id
            this.$ClearField('productType')
          } catch (error) {
            this.$Error(error)
          } finally {
            this.newProductTypeModalLoading = false
          }
        })
        .catch(() => {})
    },

    // search
    searchBrand (query) {
      if (query !== '') {
        this.brandFilter.q = {
          like: query
        }
      } else delete this.brandFilter.q

      this.getBrands()
    },
    searchProductType (query) {
      if (query !== '') {
        this.productTypeFilter.q = {
          like: query
        }
      } else delete this.productTypeFilter.q

      this.getProductTypes()
    },

    // Services fetch
    async getProduct () {
      try {
        this.loading = true
        const pdService = new ProductService(this.$route.params.id)
        const response = await pdService.get()
        this.product = response.data

        if (!this.product.image_url) {
          this.product.image_url = '/default/material-icon.jpg'
        }
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    },
    async getBrands () {
      try {
        const bdService = new BrandService()
        const result = await bdService.list(null, null, this.brandFilter)
        this.brands = result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    },
    async getProductTypes () {
      try {
        const ptService = new ProductTypeService()
        const result = await ptService.list(null, null, this.productTypeFilter)
        this.productTypes = result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    }
  },
  async mounted () {
    this.$emit('update:active', 1)

    await this.getProduct()
    this.searchBrand((this.product.brand || { name: '' }).name)
    this.searchProductType((this.product.product_type || { name: '' }).name)

    await this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Inventory' },
      { title: 'Products' },
      { title: this.product.name }
    ])
  }
}
</script>
